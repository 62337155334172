import styled from "styled-components";

import YoutubePlayer from "../YoutubePlayer/YoutubePlayer";

const HiddenSection = styled.section`
  display: none;
`;

const RandomVibrateSound = (): JSX.Element | null => {
  const value = Math.random();

  if (value < 0.9) {
    return null;
  }

  return (
    <HiddenSection>
      <YoutubePlayer id="h3axmXESErA" autoplay />
    </HiddenSection>
  );
};

export default RandomVibrateSound;
