import random from "./random-data";

export interface Song {
  title: string;
  id: string;
}
interface Playlist {
  name: string;
  songs: Song[];
}

interface Playlists {
  [key: string]: Playlist;
}

export const perjantaiPlayList: Song[] = [
  {
    id: "lOfZLb33uCg",
    title:
      '"Weird" Al Yankovic - Amish Paradise (Parody of "Gangsta\'s Paradise")',
  },
  {
    id: "eJO5HU_7_1w",
    title: "Eminem - The Real Slim Shady (Official Video - Clean Version)",
  },
  { id: "OhEHB0a7Uyg", title: "David Allan Coe- If that ain't country" },
  {
    id: "1vrEljMfXYo",
    title: "John Denver - Take Me Home, Country Roads (Official Audio)",
  },
  {
    id: "9Gc4QTqslN4",
    title:
      "The Trashmen - Surfin Bird - Bird is the Word 1963 (RE-MASTERED) (ALT End Video) (OFFICIAL VIDEO)",
  },
  {
    id: "4zAThXFOy2c",
    title: "Chris Stapleton - Tennessee Whiskey (Official Audio)",
  },
  {
    id: "VLnWf1sQkjY",
    title: "The Lonely Island - Jizz In My Pants (Official Music Video)",
  },
  { id: "hvKyBcCDOB4", title: "Darius Rucker - Wagon Wheel" },
  {
    id: "r_0JjYUe5jo",
    title: "Eminem - Godzilla ft. Juice WRLD (Directed by Cole Bennett)",
  },
  { id: "YVkUvmDQ3HY", title: "Eminem - Without Me (Official Music Video)" },
  { id: "JC9O5g6SFGA", title: "I'm a Mechanic" },
  { id: "gAYL5H46QnQ", title: "Threw It On The Ground" },
  { id: "NeQM1c-XCDc", title: "Rammstein - Deutschland (Official Video)" },
  { id: "WeYsTmIzjkw", title: "Afroman - Because I Got High" },
  { id: "gOMhN-hfMtY", title: "Eminem - Stan (Long Version) ft. Dido" },
  { id: "sNPnbI1arSE", title: "Eminem - My Name Is (Official Music Video)" },
  { id: "8AHCfZTRGiI", title: "Johnny Cash - Hurt" },
  {
    id: "N9qYF9DZPdw",
    title: '"Weird Al" Yankovic - White & Nerdy (Official Music Video)',
  },
  { id: "_lK4cX5xGiQ", title: "Tenacious D - Tribute (Official Video)" },
  { id: "hiOjK992bPU", title: "LINDEMANN - Steh auf (Official Video)" },
  { id: "QWE_M0CX9So", title: "LINDEMANN - Praise Abort (Official Video)" },
  {
    id: "Dji_km6UJvA",
    title: "LINDEMANN - LINDEMANN - Frau & Mann (Official Video)",
  },
  {
    id: "p64X_5GX0J8",
    title: "LINDEMANN - LINDEMANN - Knebel (Official Video)",
  },
  {
    id: "kTb69WkBbvs",
    title:
      "Iceland - LIVE - Hatari - Hatrið mun sigra - Grand Final - Eurovision 2019",
  },
  { id: "329vcSNGKQQ", title: "Silakka-apajalla" },
  {
    id: "Re_1HrfOMZ4",
    title: "Zlad! - Elektronik Supersonik (Official Video) [HD REUPLOAD]",
  },
  {
    id: "8DsvLdDaotM",
    title: "Vasas flora och fauna - Tesla vill int komma hit",
  },
];

const rammstein: Song[] = [
  { id: "Rr8ljRgcJNM", title: "Amerika" },
  { id: "1M4ADcMn3dA", title: "Keine Lust" },
  { id: "gNdnVVHfseA", title: "Mutter" },
  { id: "hC869VWk9_A", title: "Reise, Reise" },
  { id: "6iaxDxHUWP8", title: "Mein Land" },
  { id: "IxuEtL7gxoM", title: "Ich Tu Dir Weh" },
  { id: "Ph-CA_tu5KA", title: "Links 2 3 4" },
  { id: "af59U2BRRAU", title: "Rosenrot" },
  { id: "lSj_yy4s74U", title: "Zerstoren" },
  { id: "x2rQzv8OWEY", title: "Engel" },
  { id: "NeQM1c-XCDc", title: "Deutschland" },
  { id: "ZkW-K5RQdzo", title: "Feuer Frei!" },
  { id: "PBvwcH4XX6U", title: "Mein Teil" },
  { id: "z0wK6s-6cbo", title: "Benzin" },
  { id: "U5HAEzEk8QM", title: "Paris - Du Hast" },
  { id: "_EVKy35L7MM", title: "Mann Gegen Mann" },
  { id: "DG9TeLISmZU", title: "Paris - Mutter" },
  { id: "R9U7jGk26QM", title: "Ich will (Völkerball Version)" },
  { id: "Y42YJ-i5jW0", title: "Paris" },
  { id: "WXv31OmnKqQ", title: "Mein Herz Brennt" },
  { id: "LIPc1cfS-oQ", title: "Ohne Dich" },
  { id: "W3q8Od5qJio", title: "Du Hast" },
  { id: "GukNjYQZW8s", title: "Haifisch" },
  { id: "10JDA8SvwX8", title: "Stripped" },
  { id: "EOnSh3QlpbQ", title: "Ich Will" },
  { id: "pat2c33sbog", title: "Ausländer" },
  { id: "rrmsJhf89MY", title: "Du Riechst So Gut '98" },
  { id: "vqnk9HWbKRA", title: "Paris - Wollt Ihr Das Bett In Flammen Sehen?" },
  { id: "srN1GsnBui8", title: "Seemann" },
  { id: "Dv6Th7kJ64Q", title: "Mein Herz Brennt, Piano Version" },
  { id: "_sM2sKk4DPM", title: "Rammstein" },
];

const lindemann: Song[] = [
  { title: "Steh auf", id: "hiOjK992bPU" },
  { title: "Ach so gern", id: "xkEju-deJsE" },
  { title: "Knebel", id: "p64X_5GX0J8" },
  { id: "GbfeH6Q8PzY", title: "Ich hasse Kinder" },
];

const daftpunk: Song[] = [
  { id: "3MteSlpxCpo", title: "Pentatonix" },
  { id: "FxzBvqY5PP0", title: "Digital Love" },
  { id: "D8K90hX4PrE", title: "Technologic" },
  { id: "sFZjqVnWBhc", title: "Robot Rock" },
  { id: "bRt5z880CFY", title: "Superheroes" },
  { id: "mmi60Bd4jSs", title: "Da Funk" },
  { id: "HCfPhZQz2CE", title: "Get Lucky" },
  { id: "L93-7vRfxNs", title: "Aerodynamic" },
  { id: "dKJfJMMsqX4", title: "Face To Face" },
  { id: "A2VpR8HahKc", title: "One more time" },
  { id: "NF-kLy44Hls", title: "Lose Yourself to Dance (Official Version)" },
  { id: "gAjR4_CbPpQ", title: "Harder Better Faster" },
  { id: "HhZaHf8RP6g", title: "Veridis Quo" },
  { id: "sOS9aOIXPEk", title: "Something About Us" },
  { id: "qFLhGq0060w", title: "The Weeknd - I Feel It Coming ft. Daft Punk" },
];

const pmmp: Song[] = [
  { id: "Rc9jkAwSuvw", title: "Täti Monika" },
  { id: "uLwU8jNmDNk", title: "Koko show" },
  { id: "nkV9uq7cdfE", title: "Lautturi" },
  { id: "uW8m_w75vRs", title: "Rusketusraidat" },
  { id: "QjJ7drm_oqE", title: "Pikkuveli" },
  { id: "xu51l6Ei8dE", title: "Joutsenet" },
  { id: "_KvM_l1Z1UY", title: "Joku raja" },
  { id: "xBGTDL3Ceko", title: "Matkalaulu" },
  { id: "a5yN_TEf5bU", title: "Katinka" },
  { id: "kb9BILNEiSM", title: "Rakkaalleni" },
  { id: "gXtYcDCnOH4", title: "oo siellä jossain mun" },
  { id: "xtvZxMcGADQ", title: "Heliumpallo" },
  { id: "qW19EIVQXgs", title: "Henkilökohtaisesti" },
  { id: "paO_XFJr85s", title: "Tässä elämä on" },
  { id: "aPm7mlXs7p0", title: "Kesäkaverit" },
  { id: "1Glohk5vPOk", title: "Kovemmat kädet" },
  { id: "PUohs9j9jAw", title: "Päiväkoti" },
  { id: "wYkY09_RH-I", title: "Pariterapiaa" },
  { id: "7zrS7kbLFss", title: "Tytöt" },
  { id: "yPFfX148FLU", title: "Isin pikku tyttö" },
  { id: "pbe-r0ZUE28", title: "Viimeinen valitusvirsi" },
  { id: "NSaelIrQGdE", title: "Kumivirsi" },
  { id: "Xr6sNseOuSQ", title: "Niina" },
  { id: "7nk0yKu1kLs", title: "Mummola" },
  { id: "TtWFdXUFuYc", title: "Mustan kissan tango" },
  { id: "fELKSbNpi1Q", title: "Kesä -95" },
  { id: "yT7GNC3cauE", title: "Olkaa yksin ja juoskaa karkuun" },
  { id: "1vtrVtAdQfs", title: "Minä soitan harmonikkaa" },
  { id: "BWqv3DJoTIY", title: "Lapsuus Loppui" },
  { id: "fNOKKDqnYTw", title: "Magdaleena" },
  { id: "l6tG3Bjw1No", title: "Jospa minä kissan saisin" },
  { id: "qqR2AcMl-qA", title: "Korkeasaari" },
  { id: "SoLOsGapu8Y", title: "Hyvin hiljaa" },
  { id: "yoBGC8E8Kcg", title: "Salla tahtoo siivet" },
  { id: "mY0nXR2fpic", title: "Valloittamaton (Audio video)" },
  { id: "8OcNiXYNaXQ", title: "Pahvinaamari" },
  { id: "2wDHV6Sgc88", title: "Kuvia" },
  { id: "MYUNrQDN0eY", title: "Merimiehen Vaimo" },
  { id: "vsQo0C4Hsgg", title: "4ever young" },
  { id: "GmL-sdLeFUw", title: "Taiteilia" },
  { id: "rNAMj06ityo", title: "Kiitos" },
  { id: "eJNF1fQCi_8", title: "Puuhevonen" },
  { id: "vjbzG5N7eZ4", title: "Onko Sittenkään Hyvä Näin" },
  { id: "CAw4qNOP4dM", title: "neljä kissanpoikaa" },
  { id: "9KEVObbduHk", title: "Etkö ymmärrä" },
  { id: "jnDEPXVIWeg", title: "Pikku Pikku Lauri" },
  { id: "uIfz_OHmLhw", title: "Lörpötys" },
  { id: "juZbPBX_T5g", title: "Kevään valo" },
  { id: "B0akNJp6rIA", title: "Poika" },
  { id: "1dP460g092M", title: "San Francisco" },
  { id: "OoJW-UorpkA", title: "Taajama" },
  { id: "gCIJ6jr9h0k", title: "Päät soittaa" },
  { id: "1KiygvaneOE", title: "Odotan" },
  { id: "PN2s92hLgl4", title: "Atsipoppaa" },
  { id: "UnDand-dPh4", title: "Hyljätty nukke" },
  { id: "2_5jZHjulmE", title: "Kohkausrock" },
  { id: "S8Kl3itDub0", title: "Ikuinen leikki" },
  { id: "ark9FH6oQBI", title: "Toivo" },
  { id: "zmRh4Yhh_eI", title: "Leskiäidin tyttäret" },
  { id: "EDpFlRjlfEU", title: "Saunalaulu (Rusketusraidat Demo)" },
  { id: "29i2h3oALWA", title: "Tulva" },
  { id: "3VFfYNhT3B8", title: "Onnellinen Päivä" },
  { id: "veRanCpnQ-4", title: "Jeesus ei tule oletko valmis" },
  { id: "1Cvl-OuRn68", title: "Se Vaikenee Joka Pelkää" },
];

const worldburn: Song[] = [
  { id: "_S85d4MX7-I", title: "Worldburn - Sense of Security" },
  { id: "SDH-Nywqka0", title: "Worldburn - Shortcut" },
  { id: "nYIFe8NuIfQ", title: "Worldburn - Oblivious" },
  { id: "h0hnpLj6yn0", title: "Worldburn - Era of Consumption" },
  { id: "ZUVyRCrmRSU", title: "Worldburn - A.I." },
  { id: "WKX07TdqhWs", title: "Worldburn - Gluttony" },
  { id: "-hdn4980H0g", title: "Worldburn - Process" },
  { id: "ZzUMrfu1rPY", title: "Worldburn - Velkavaltio" },
  { id: "8PLjvuJgD1Y", title: "Worldburn - Refuse to Suffer" },
  { id: "1qK4Igowpzg", title: "Worldburn - Babylonian kahleet" },
  { id: "GpQRE-hLqQs", title: "Worldburn - Steroid Penis" },
  { id: "F2fnsrGCTPk", title: "Worldburn - Nuclear Flatulence" },
  { id: "snz37PD6Vms", title: "Worldburn - Rise" },
  { id: "EEgUKii2ycM", title: "Worldburn - Crush the Faithless" },
];

const nirvana: Song[] = [
  { id: "PbgKEjNBHqM", title: "In Bloom" },
  { id: "AhcttcXcRYY", title: "About A Girl" },
  { id: "aWmkuH1k7uA", title: "All Apologies" },
  { id: "qv96yJYhk3M", title: "You Know You're Right" },
  { id: "QECJ9pCyhns", title: "Sliver" },
  { id: "Ee6xkwVucIE", title: "Breed" },
  { id: "UThKn_TmfmM", title: "Heart-Shaped Box" },
  { id: "hTWKbfoikeg", title: "Smells Like Teen Spirit" },
  {
    id: "T5LtSKKr7oY",
    title: "Oh Me (Live On MTV Unplugged, 1993 / Unedited)",
  },
  {
    id: "ZEh3mO9MPV0",
    title: "Love Buzz (Live At The Paramount, Seattle / 1991)",
  },
  {
    id: "KWbXFlH9cQs",
    title: "Come As You Are (Live And Loud, Seattle / 1993)",
  },
  {
    id: "iz3tmYDl6XI",
    title: "The Man Who Sold The World (Live And Loud, Seattle / 1993)",
  },
  { id: "ly1lWkRwm6g", title: "Me (Live At The Paramount, Seattle / 1991)" },
  {
    id: "e3ZAJevR_4Q",
    title: "Lithium (Live At The Paramount, Seattle / 1991)",
  },
  { id: "QVlo81A5mHc", title: "Blew (Live And Loud, Seattle / 1993)" },
  {
    id: "ikkhmkAH72U",
    title: "My Best Friend's Girl (Live In Munich, Germany/1994)",
  },
  {
    id: "EO9l8TooOFg",
    title: "Plateau (Live On MTV Unplugged, 1993 / Rehearsal)",
  },
  {
    id: "L6PTI0Gh5zU",
    title: "Pennyroyal Tea (Live And Loud, Seattle / 1993)",
  },
  {
    id: "H96MEx73tDc",
    title: "Radio Friendly Unit Shifter (Live And Loud, Seattle / 1993)",
  },
  {
    id: "RTDIzedHTiY",
    title:
      "Jesus Doesn't Want Me For A Sunbeam (Live At The Paramount, Seattle / 1991)",
  },
  {
    id: "DeoWhvsyOZ8",
    title: "School (Live At The Paramount, Seattle / 1991)",
  },
  {
    id: "qofd8l27ieY",
    title: "Floyd The Barber (Live At The Paramount, Seattle / 1991)",
  },
  {
    id: "BMNggCLznsY",
    title: "Serve The Servants (Live And Loud, Seattle / 1993)",
  },
];

const stargate: Song[] = [
  { id: "MhjkVCAqWnE", title: "Singularity - Time Is Up / Finale" },
  { id: "KFdq3X9W2uM", title: "Singularity - To The Underground Site" },
  {
    id: "q8hWP8WDxgY",
    title: "Singularity - Jack And Teal'c Escaping Battle",
  },
  { id: "WPln3Q1JR0U", title: "Singularity - Heart Attack And Operation" },
  {
    id: "zO9S5YLURdM",
    title: "Singularity - Sam And Little Girl Get Closer",
  },
  {
    id: "utOob6MqaiM",
    title: "Singularity - Sam With Girl / Back Through Gate",
  },
  { id: "Mca2WWcrWYQ", title: "Singularity - From Stargate To New World" },
  { id: "6J9-Rvjpnbc", title: "Singularity - Teaser" },
  { id: "j2ovIaB1a-I", title: "The Serpent's Lair - Finale" },
  {
    id: "pLveiLXTotQ",
    title: "In the Line of Duty - Finale / Daniel And Then Others Visit",
  },
  {
    id: "mdN1aQBVfCQ",
    title: "In the Line of Duty - Daniel Talks To Alien Carter",
  },
  {
    id: "GWNmZiT7APM",
    title: "In the Line of Duty - Bad Guy Bandages Doc",
  },
  {
    id: "gDRJCl1vgDo",
    title: "In the Line of Duty - Daniel Talks To Girl Survivor",
  },
  {
    id: "gxy3gbo0uLI",
    title: "In the Line of Duty - Teal'c Gives O'Neill Advice",
  },
  {
    id: "w-qjIXj0jFw",
    title: "In the Line of Duty - O'Neill To Burn Victim",
  },
  {
    id: "-gfXbCpnYp0",
    title: "In the Line of Duty - O'Neill Comforts Cassie",
  },
  { id: "RFfqHkjr-t4", title: "In the Line of Duty - Medical Time" },
  { id: "1pK3iHsEzjw", title: "In the Line of Duty - Teaser" },
  {
    id: "nnpMsin4tAQ",
    title: "Cold Lazarus - Jack Meets Alien Self / Finale",
  },
  {
    id: "asHHLiBJ8Yc",
    title: "Cold Lazarus - Pushing Back Through Gate To Hospital",
  },
  {
    id: "JLu-wZ-Phqs",
    title: "Cold Lazarus - They Reactivate The Crystal Monitor",
  },
  {
    id: "60sfBzFFDqs",
    title: "Cold Lazarus - Jack And Wife On Park Bench",
  },
  { id: "Ba9UIQu7xIs", title: "Cold Lazarus - The Crystal Monitor" },
  { id: "b2cHQK6Rgdw", title: "Cold Lazarus - The Crystals" },
  { id: "ZC6ea9sir9E", title: "Cold Lazarus - Jack Visits Charlie's Room" },
  { id: "NJtUHupKplI", title: "Cold Lazarus - Jack At Ex-Wife's House" },
  { id: "we5Ln0fXgo0", title: "Cold Lazarus - Is It Really Jack?" },
  { id: "J1Xrhw7lZT4", title: "Cold Lazarus - Teaser" },
  { id: "nfIKKtN2OkE", title: "Shardlight - Original Demo Pitch" },
  { id: "-9LEEn-w_qM", title: "Shardlight - Reactor (Original Demo)" },
];

const nylonbeat: Song[] = [
  { id: "nPZ8hawiuhk", title: "Mikkitelineestä on puolet minun" },
  { id: "mKK32ZB12bA", title: "Rakastuin mä looseriin" },
  { id: "ZCmDWwJ30OI", title: "Satasen laina" },
  { id: "acdkmEdv_q4", title: "Seksi vie ja taksi tuo" },
  { id: "OHwRoRB6u_0", title: "like a fool" },
  { id: "qz1UF2u5Ml4", title: "Naparengas" },
  { id: "IYdHCjXcs1E", title: "Viimeinen" },
  { id: "wkvB2kGHthM", title: "Teflon love" },
  { id: "rnP8AKAh_7M", title: "Kuumalle hiekalle" },
  { id: "J5iL9TmIWO4", title: "huume" },
  { id: "jJTWhJMXCEo", title: "Syytön" },
  { id: "whu4_AXeHxg", title: "Jos" },
  { id: "5NtkyUmCPO4", title: "Sanoja" },
  { id: "JcB8hIbMHLo", title: "Orja" },
  { id: "L6LF41vQqVY", title: "Sairaanfuckingkipeenraastavaa" },
  { id: "HPpBbxSqAiE", title: "Jonain päivänä" },
  { id: "htHcXof3FfY", title: "Ainut jonka sain" },
  { id: "N0nFShHt-Ks", title: "Valehtelija" },
  { id: "a9TJJfF2qXk", title: "Venäjää" },
  { id: "PZi7kbqDQbU", title: "Musta joulu" },
  { id: "kwyZIa6jeWE", title: "Kreisi oon" },
  { id: "ykzHdFtPCSo", title: "Lä-lä-lä" },
  { id: "M8mBy5zPmKY", title: "Liikaa" },
  { id: "hyFiC2To7JY", title: "Paikka palaa" },
  { id: "wPLjxpfbT6M", title: "Takapulpetin poika" },
  { id: "_9Eizzo45KY", title: "Nylonkuu" },
  { id: "-L205pTZ31U", title: "Syntinen" },
  { id: "sZ0Vw-lzwdY", title: "Valmis oon vaikka armeijaan" },
  { id: "MeHlgAtL8HU", title: "Hei - me tehdään ydinkoe" },
  { id: "x6YNUgzTdfg", title: "Nuoruustango" },
  { id: "OKIu_lSjmRI", title: "Demoja" },
  { id: "qzR3yVtHuAY", title: "Adios" },
  { id: "GHpbz2gcFHg", title: "Why me, why you?" },
  { id: "qmrY06lxjPY", title: "NYPD Blue" },
  { id: "bjsQjqm39iI", title: "Don't Disappoint Me" },
  { id: "NocXY9GCyKc", title: "Mies" },
  { id: "THJ4bf4k75A", title: "Satelliitti irrallaan" },
  { id: "Pr99okn0DQk", title: "Koditon" },
  { id: "LAczlJMX9NI", title: "If I Had Your Number" },
  { id: "UjVxH4yuU9w", title: "Larias - Faija päivystää" },
  { id: "yws3b6FHXlY", title: "Patteri" },
  { id: "ovxi3wE858M", title: "Mikset mua sä jättänyt oo" },
  { id: "T4yA_zcnVDs", title: "Hän Sinun On" },
  { id: "VMCudsdNDUY", title: "Fighter" },
  { id: "sOlEtSPBeps", title: "Haloo Avaruus" },
  { id: "ne29I95BYNw", title: "Africa at night" },
  { id: "3FqoKiHw50Q", title: "Missä mennään" },
  { id: "uh8QNEJWaRg", title: "Sukelletaan" },
  { id: "rUjosuhds90", title: "Petollinen päiväkirja" },
  { id: "vRZq4dN8p1g", title: "Jos Käden Annat Vain" },
  { id: "f59nIQmoQTI", title: "Kun kundi pelkää" },
  { id: "ZrD8h5Tg1Ow", title: "Ne selviytyy" },
  { id: "P6PzwcbEGM8", title: "pieni taivas" },
  { id: "9nd3oWeOU0I", title: "Elämä On Lyhyt" },
  { id: "HkEenUOR4uk", title: "Yo-Yo" },
  { id: "u5HPh4H1ZvA", title: "Tell It to Your Mother" },
  { id: "j45UZmRIQTQ", title: "Soita mulle" },
  { id: "qKtQOhVaVX8", title: "Vaihdan" },
  { id: "anb46OFoMV8", title: "Comeback" },
  { id: "ob9ERrw4d2U", title: "kevytlinja" },
  { id: "njkR3pLKg1s", title: "L.O.V.E. Blue" },
  { id: "wPcBSBnqrCM", title: "Miksi on niin vaikeaa" },
  { id: "9XYbG1gtBp0", title: "Vaikka kuolisin" },
  { id: "77AcExKESr0", title: "If" },
  { id: "Uk27Q5-MHbw", title: "Lääke ikävään" },
  { id: "i-SivKEYB44", title: "Seksi vie 12 apinaa kuumalle hiekalle" },
];

const blacksabbath: Song[] = [
  { id: "0qanF-91aJo", title: "Paranoid" },
  { id: "5s7_WbiR79E", title: "Iron Man" },
  { id: "fWvKvOViM3g", title: "Paranoid (Full Album)" },
  { id: "o0W91FrTlYk", title: "End of the Beginning" },
  { id: "hV2ideRjDIk", title: "Loner" },
  { id: "wVfUimq2KeI", title: "God Is Dead?" },
  { id: "0lVdMbUx1_k", title: "Black Sabbath" },
  { id: "1fqGIpkp2NU", title: "Master of Reality (Full Album)" },
  { id: "Uq42HUUJFzU", title: "Paranoid" },
  { id: "mfTpjrzas5E", title: "Sabbath Bloody Sabbath" },
  { id: "jCehccEZzX4", title: "Killing Yourself To Live" },
  { id: "FqvThopYOLY", title: "Loner" },
  { id: "Kt0Xuw02TMQ", title: "Rock 'N' Roll Doctor" },
  { id: "2Q6gPouusHs", title: "Never Say Die" },
  { id: "ni1i85rT-RY", title: "The Final Song & Final Bow" },
  { id: "R17k8zkSRZs", title: "A Hard Road" },
];

const gorillaz: Song[] = [
  { id: "1V_xRb0x9aw", title: "Clint Eastwood" },
  { id: "5qJp6xlKEug", title: "Saturnz Barz (Spirit House)" },
  { id: "HyHNuVaZJ-k", title: "Feel Good Inc." },
  { id: "nhPaWIeULKk", title: "Stylo" },
  { id: "cLnkQAeMbIM", title: "Dirty Harry" },
  { id: "9W44NWYwa1g", title: "Andromeda" },
  { id: "uAOR6ib95kQ", title: "DARE" },
  { id: "04mfKJWDSzI", title: "On Melancholy Hill" },
];

const rhcp: Song[] = [
  { id: "YlUKcNNmywk", title: "Californication" },
  { id: "Q0oIoR9mLwc", title: "Dark Necessities" },
  { id: "8DyziWtkfBw", title: "Can't Stop" },
];

const doors: Song[] = [{ id: "7G2-FPlvY58", title: "Riders on the Storm" }];
const barbie: Song[] = [
  {
    id: "wwux9KiBMjE",
    title:
      "Ryan Gosling - I'm Just Ken (From Barbie The Album) [Official Audio]",
  },
];

const indie20112013: Song[] = [
  {
    id: "MV_3Dpw-BRY",
    title:
      "Kavinsky - Nightcall (Drive Original Movie Soundtrack) (Official Audio)",
  },
  {
    id: "-5FKNViujeM",
    title: "Kavinsky - Pacific Coast Highway (Official Audio)",
  },
  { id: "dX3k_QDnzHE", title: "M83 'Midnight City' Official video" },
];

const playlists: Playlists = {
  rammstein: { name: "Rammstein", songs: rammstein },
  lindemann: { name: "Lindemann", songs: lindemann },
  daftpunk: { name: "Daft Punk", songs: daftpunk },
  pmmp: { name: "PMMP", songs: pmmp },
  worldburn: { name: "Worldburn", songs: worldburn },
  nirvana: { name: "Nirvana", songs: nirvana },
  stargate: { name: "Stargate SG-1", songs: stargate },
  nylonbeat: { name: "Nylon Beat", songs: nylonbeat },
  blacksabbath: { name: "Black Sabbath", songs: blacksabbath },
  gorillaz: { name: "Gorillaz", songs: gorillaz },
  rhcp: { name: "Red Hot Chili Peppers", songs: rhcp },
  doors: { name: "The Doors", songs: doors },
  random: { name: "random", songs: random },
  barbie: { name: "Barbie", songs: barbie },
  indie20112013: {
    name: "2011-2013, stuff I listened during these years, maybe.",
    songs: indie20112013,
  },
  perjantaiPlaylist: { name: "Perjantai playlist", songs: perjantaiPlayList },
};

export const allSongs = Object.values(playlists)
  .map(({ songs }) => songs)
  .reduce((list, songs) => [...list, ...songs], []);

export default playlists;
